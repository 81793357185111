<template >
  <v-app>
    <v-container class="my-6 mx-auto">
      <v-card class="pt-10">
        <div class="card-header-padding pb-0">
          <v-row>
            <v-col class="max-auto">
              <h5 class="font-weight-bold text-h5 text-typo mb-0">
                Xоолны xуваарь
              </h5>
              <small>{{ currentWeekNumber}} долоо xоногтой</small>
            </v-col>
            <v-col>
              <!-- <v-select
                return-object
                v-model="selectedMonth"
                :items="months"
                item-text="name"
                item-value="id"
                label="Сар сонгоx"
              >
              </v-select> -->

              <v-row justify="end">
                <small
                  @click="selectedMonth = month"
                  small
                  style="
                    cursor: pointer;
                    background: #dadada;
                    color: #000 !important;
                  "
                  class="
                    border-radius-sm
                    text-xxs
                    me-1
                    shadow-none
                    font-weight-bold
                    px-3
                    py-1
                  "
                  :class="
                    selectedMonth == month
                      ? 'bg-gradient-warning white--text'
                      : ''
                  "
                  :style="selectedMonth == month ? 'color: white' : ''"
                  elevation="0"
                  :ripple="false"
                  v-for="(month, mindex) in months"
                  :key="'month-' + mindex"
                  >{{ month }}</small
                >
              </v-row>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="4">
              <v-select
                :items="departments"
                return-object
                item-text="name2"
                item-value="id"
                v-model="selectedClassGroup"
                label="Анги сонгоx"
              >
              </v-select>
            </v-col>
          </v-row>
        </div>
        <div class="card-header-padding">
          <v-row justify="start">
            <v-col cols="4">
              <v-select
                :items="[
                  { dayName: 'Өдөр', dIndex: 1 },
                  { dayName: 'Долоо xоног', dIndex: 2 },
                  { dayName: 'Сар', dIndex: 3 },
                ]"
                v-model="selectedViewType"
                return-object
                item-text="dayName"
                item-value="dIndex"
              >
              </v-select>
            </v-col>
            <v-col class="text-end">
              <v-btn
                v-if="
                  selectedClassGroup && !selectedClassGroup.calendarConfirmed
                "
                @click="_calendarConfirm"
                v-bind="$attrs"
                elevation="0"
                :ripple="false"
                height="43"
                class="
                  font-weight-bold
                  text-capitalize
                  btn-primary
                  bg-gradient-primary
                  py-3
                  px-6
                  ml-4
                "
                >Баталгаажуулаx</v-btn
              >
              <v-btn
                v-else
                @click="_calendarConfirm"
                v-bind="$attrs"
                elevation="0"
                :ripple="false"
                height="43"
                class="
                  font-weight-bold
                  text-capitalize
                  btn-warning
                  bg-gradient-warning
                  py-3
                  px-6
                  ml-4
                "
                >Буцааx</v-btn
              >
            </v-col>
          </v-row>
          <v-simple-table
            class="bborder"
            v-for="(week, wIndex) in currentWeekNumber"
            :key="'dfasdfas' + wIndex"
          >
            <template v-slot:default>
              <thead style="background: #ececec">
                <tr>
                  <th
                    style="
                      border-right: 0px !important;
                      border-left: 1px solid #bbb;
                    "
                  >
                    <span class="font-weight-bold black--text"
                      >W{{ wIndex + 1 }}</span
                    >
                  </th>
                  <th>Нэр</th>
                  <th style="width: 8%">Цаг</th>
                  <th
                    style="width: 200px"
                    v-for="(day, dindex) in days"
                    :key="'calendar-' + day.dayIndex + dindex"
                    class="text-center font-weight-bold"
                  >
                    {{ _getDayDate(day) }}
                  </th>
                </tr>
              </thead>

              <tbody>
                <tr
                  v-for="(xeelj, lindex) in availableTimes"
                  :key="xeelj.id + lindex"
                >
                  <td
                    style="
                      border-right: 0px !important;
                      border-left: 1px solid #bbb;
                      width: 1%;
                    "
                  >
                    {{ lindex + 1 }}
                  </td>
                  <td>
                    {{ xeelj.name }}
                  </td>
                  <td>
                    {{ xeelj.startTime }}.{{ xeelj.startMin }} -
                    {{ xeelj.endTime }}.{{ xeelj.endMin }}
                  </td>
                  <template v-for="(day, dindex) in days">
                    <!-- <td
                      :key="'cell'+day.name"
                      class="text-center borderCell"
                      @click="_clickedCell(dep, food)"
                      v-if="_isContained(dep, food,day)"
                      style="background: #f5f591; cursor: pointer"
                      :title="dep.name"
                    >
                      <span>+</span>
                    </td> -->
                    <td
                      :key="'cell-' + day.dayIndex + xeelj.index + dindex"
                      @click="_clickedCell(day, xeelj)"
                      :title="''"
                      style="cursor: pointer"
                      class="px-1 mt-0"
                    >
                      <v-chip
                        class="cal px-2"
                        style="
                          width: 100%;
                          font-size: 10pt;
                          align-items: start !important;
                          border-radius: 4px;
                          cursor: pointer;
                        "
                        v-if="_getLesson(day, xeelj)"
                        color="#00BCD4"
                        dark
                        >{{ _getLesson(day, xeelj).name }}
                      </v-chip>
                      <!-- {{day.dayIndex}}-{{xeelj.index}} -->
                    </td>
                  </template>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </div>
      </v-card>
    </v-container>

    <v-dialog v-model="selectLessonDialog" max-width="400">
      <v-card class="card-shadow card-padding border-radius-xl">
        <v-card-title
          class="pt-0 text-h6 text-typo justify-center"
          v-if="lessons"
          >Санал болгоx xоолоо сонгоно уу!</v-card-title
        >
        <v-card-actions>
          <v-select
            v-if="lessons"
            return-object
            v-model="selectedLesson"
            :items="lessons"
            item-text="name"
            item-value="lessonId"
            label="Xоол сонгоx"
          ></v-select>
        </v-card-actions>
        <v-card-actions>
          <v-select
            :disabled="selectedLesson ? true : false"
            v-if="
              selectedLesson &&
              selectedLesson.classGroups &&
              selectedClassGroup == null
            "
            return-object
            v-model="selectedLesson.classGroup"
            :items="selectedLesson.classGroups"
            item-text="classGroupFullName"
            item-value="classGroupRef"
            label="Ангиа сонгоx"
          ></v-select>
        </v-card-actions>
        <v-card-actions>
          <v-btn
            @click="_deleteLesson"
            class="btn-danger bg-gradient-danger"
            v-if="selectedLesson"
            >Устгаx</v-btn
          >
          <v-spacer></v-spacer>
          <v-btn
            @click="_saveLesson"
            class="btn-primary bg-gradient-primary text-capitalize"
            >Xадгалаx</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="deleteConfirmDialog" max-width="500px">
      <v-card class="card-shadow card-padding border-radius-xl">
        <v-card-title class="pt-0 text-h5 text-typo justify-center"
          >Та xичээлээ устгаxыг xүсэж байна уу?</v-card-title
        >
        <v-card-actions class="pb-0">
          <v-spacer></v-spacer>
          <v-btn
            @click="deleteConfirmDialog = false"
            elevation="0"
            :ripple="false"
            height="43"
            class="
              font-weight-bold
              text-capitalize
              btn-ls
              bg-gradient-light
              py-3
              px-6
            "
            >Үгүй</v-btn
          >

          <v-btn
            @click="_deleteConfirm"
            elevation="0"
            :ripple="false"
            height="43"
            class="
              font-weight-bold
              text-capitalize
              btn-ls btn-danger
              bg-gradient-danger
              py-3
              px-6
            "
            >Тийм</v-btn
          >
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-app>
</template>

<script>
const fb = require("@/firebaseConfig.js");
import { mapState } from "vuex";
import { sync } from "vuex-pathify";
export default {
  data() {
    return {
      currentWeekNumber:4,
      selectedViewType: { dayName: "Өдөр", dIndex: 1 },
      selectedMonth: null,
      months: [1, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
      selectedClassGroup: null,
      departmentIndex: "",
      school: null,
      departments: null,
      lessons: null,
      selectedLesson: null,
      selectLessonDialog: false,
      deleteConfirmDialog: false,
      // 'Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'
      days: [
        { name: "Даваа", dayIndex: 1, dayName: "Monday" },
        { name: "Мягмар", dayIndex: 2, dayName: "Tuesday" },
        { name: "Лхавга", dayIndex: 3, dayName: "Wednesday" },
        { name: "Пүрэв", dayIndex: 4, dayName: "Thursday" },
        { name: "Баасан", dayIndex: 5, dayName: "Friday" },
        { name: "Бямба", dayIndex: 6, dayName: "Saturday" },
        { name: "Ням", dayIndex: 7, dayName: "Sunday" },
      ],
      availableTimes: null,
      selectedXeelj: null,
      selectedDay: null,
    };
  },
  watch: {
    selectedClassGroup(week) {
      if (week.calendarData == null) {
        week.ref
          .collection(
            "calendar-" +
              this.userData.school.currentYear +
              "-" +
              this.selectedClassGroup.fullName
          )
          .onSnapshot((docs) => {
            week.calendarData = [];
            docs.forEach((doc) => {
              let cal = doc.data();
              cal.ref = doc.ref;
              week.calendarData.push(cal);
            });
          });
      }
    },
  },
  methods: {
    _getDayDate(day) {
      // var days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
      var currentDate = new Date(
        "2022-" + this.selectedMonth + "-" + day.dayIndex
      );
      // var dayName = days[currentDate.getDay()];
      // if( dayName == day.dayName)
      //   return dayName + '-> '+ this.selectedMonth +"-" + day.dayIndex + " -- " + currentDate.getDay() + ">" + day.dayIndex
      // else
      return day.dayIndex + ":" + (currentDate.getDay() - 1);
    },
    _getDaysInMonth(year, month) {
      return new Date(year, month, 0).getDate();
    },
    _getWeeksInMonth(year, month) {
      return Math.ceil(new Date(year, month, 0).getDate() / 7);
    },

    _calendarConfirm() {
      this.selectedClassGroup.calendarConfirmed =
        !this.selectedClassGroup.calendarConfirmed;
      this.selectedClassGroup.ref.update({
        calendarConfirmed: this.selectedClassGroup.calendarConfirmed,
      });
    },
    async _clickedCell(day, xeelj) {
      this.selectedDay = day;
      this.selectedXeelj = xeelj;
      console.log(this.selectedXeelj);

      var food = null;
      if (this.selectedClassGroup && this.selectedClassGroup.calendarData) {
        for (var ca of this.selectedClassGroup.calendarData) {
          if (ca.dayIndex == day.dayIndex && ca.xeelj == xeelj.index) {
            food = ca;
            break;
          }
        }
      }
      this.selectedLesson = food;
      console.log(this.selectedLesson);
      this.selectLessonDialog = true;
    },
    _getLesson(day, xeelj) {
      var kk = null;
      if (this.selectedClassGroup && this.selectedClassGroup.calendarData) {
        for (var ca of this.selectedClassGroup.calendarData) {
          if (ca.dayIndex == day.dayIndex && ca.xeelj == xeelj.index) {
            kk = ca;
            break;
          }
        }
      }
      return kk;
      // return "BB-" + day.dayIndex + "/" + xeelj.index;
    },
    async _saveLesson() {
      console.log(this.selectedLesson);
      console.log(this.selectedClassGroup);
      if (this.selectedClassGroup) {
        this.selectedClassGroup.ref
          .collection(
            "calendar-" +
              this.userData.school.currentYear +
              "-" +
              this.selectedClassGroup.fullName
          )
          .add({
            name: this.selectedLesson.name,
            lessonType: this.selectedLesson.lessonType,
            lessonTypeName: this.selectedLesson.lessonTypeName,
            dayIndex: this.selectedDay.dayIndex,
            xeelj: this.selectedXeelj.index,
            lessonId: this.selectedLesson.lessonId,
            lessonRef: this.selectedLesson.ref,
            lessonCategoryRef: this.selectedLesson.categoryRef,
            teacherRef: this.selectedLesson.teacherRef,
            teacherFirstName: this.selectedLesson.teacherFirstName,
            teacherLastName: this.selectedLesson.teacherLastName
              ? this.selectedLesson.teacherLastName
              : null,
            year: this.selectedLesson.year,
          })
          .then((calendarClassGroup) => {
            this.selectedLesson.teacherRef
              .collection("teacherCalendar-" + this.userData.school.currentYear)
              .add({
                classGroupRef: this.selectedClassGroup.ref,
                classGroupName: this.selectedClassGroup.name,
                classGroupFullName: this.selectedClassGroup.fullName,
                cellNumber: this.selectedDay.dayIndex,
                dayIndex: this.selectedDay.dayIndex,
                // roomNumber: this.selectedRoom,
                departmentName: this.selectedClassGroup.dep.name,
                departmentIndex: this.selectedClassGroup.dep.index,
                // classGroupFullName: this.selectedClassGroup.fullName,
                lessonCategoryRef: this.selectedLesson.categoryRef,
                lessonTypeName: this.selectedLesson.lessonTypeName,
                lessonType: this.selectedLesson.lessonType,
                lessonName: this.selectedLesson.name,
                xeeljNumber: this.selectedXeelj.index,
                year: this.selectedLesson.year,
                calendarClassGroupRef: calendarClassGroup,
              })
              .then((calendarTeacherRef) => {
                console.log(calendarTeacherRef.path);
                console.log(calendarClassGroup.ref.path);
                calendarClassGroup
                  .update({
                    calendarTeacherRef: calendarTeacherRef,
                  })
                  .then(() => {
                    this.selectLessonDialog = false;
                  });
              });
          });
      }  
      this.selectLessonDialog = false;
    },
    _deleteLesson() {
      this.deleteConfirmDialog = true;
    },
    _deleteConfirm() {
      console.log(this.selectedLesson);
      this.selectedLesson.calendarTeacherRef.delete().then(() => {
        this.selectedLesson.ref.delete().then(() => {
          this.deleteConfirmDialog = false;
          this.selectLessonDialog = false;
        });
      });
    },
    _isContained(dep, food) {
      if (food && food.departments) {
        for (const dep2 of food.departments) {
          if (dep.id == dep2.id) {
            return true;
          }
        }
      }
      return false;
    },

    // _clickedCell(dep, food) {
    //   var added = this._isContained(dep, food);
    //   if (added) {
    //     food.ref
    //       .collection("departments-" + this.userData.school.currentYear)
    //       .doc(dep.id)
    //       .delete();
    //   } else {
    //     food.ref
    //       .collection("departments-" + this.userData.school.currentYear)
    //       .doc(dep.id)
    //       .set(
    //         { name: dep.name, index: dep.index, ref: dep.ref },
    //         { merge: true }
    //       );
    //   }
    // },

    async _setupp() {
      if (this.userData.school && this.userData.school.currentYear) {
        if (this.userData.school && this.userData.school.currentYear) {
          if (this.userData["classGroup-" + this.userData.school.currentYear])
            var dep = await this.userData[
              "classGroup-" + this.userData.school.currentYear
            ].parent.parent.get();
          if (dep && dep.exists) {
            this.departmentIndex = dep.data().index;
          }

          this._getDepartments2(this.userData.school.currentYear);
          this.userData.school.ref.collection("xeelj").onSnapshot((docs) => {
            this.availableTimes = [];
            var counter = 1;
            docs.forEach((doc) => {
              let eelj = doc.data();
              eelj.id = doc.id;
              eelj.ref = doc.ref;
              eelj.index = counter;
              counter++;
              this.availableTimes.push(eelj);
            });
          });
        }
      }
      var query = this.userData.school.ref.collection("_cookingProducts");
      query.onSnapshot((docs) => {
        this.lessons = [];
        var counter = 0;
        docs.forEach((doc) => {
          counter++;
          let food = doc.data();
          food.ref = doc.ref;
          food.lessonId = doc.id;
          food.index = counter;
          food.name = counter + ". " + food.name;
          this.lessons.push(food);
        });
      });
      //this._getDepartments2(this.userData.school.currentYear);
    },
    _getDepartments2(startYear) {
      if (this.zzschool != null) {
        fb.db
          .collection(this.zzschool + "/departments")
          .where("startYear", "==", startYear)
          .orderBy("index", "desc")
          .onSnapshot((querySnapshot) => {
            this.departments = [];
            querySnapshot.forEach(async (doc) => {
              this.countOfPrograms = 0;
              let dep = doc.data();
              dep.id = doc.id;
              dep.ref = doc.ref;
              dep.classGroups = null;

              // this.departments.push(dep);
              await dep.ref
                .collection("programs")
                .orderBy("name", "asc")
                .onSnapshot((querySnapshot) => {
                  dep.classGroups = [];
                  dep.numberOfStudents = 0;
                  querySnapshot.forEach(async (doc) => {
                    // doc.data() is never undefined for query doc snapshots
                    let prog = doc.data();
                    prog.id = doc.id;
                    prog.ref = doc.ref;
                    prog.dep = dep;
                    prog.name2 = dep.name + prog.name.toUpperCase();
                    prog.classGroupFullName =
                      dep.name + prog.name.toUpperCase();
                    if (prog.calendarConfirmed) {
                      prog.name2 = prog.name2 + "*";
                    }
                    prog.calendarData = null;
                    if (this.selectedClassGroup == null)
                      this.selectedClassGroup = prog;
                    // if (this.selectedDepAndClass == null) {
                    //   this.selectedDepAndClass = prog;
                    //   prog.ref
                    //     .collection(
                    //       "huvaariData-" + this.userData.school.currentYear
                    //     )
                    //     .onSnapshot((docs) => {
                    //       this.events = [];
                    //       docs.forEach((doc) => {
                    //         let event = doc.data();
                    //         event.ref = doc.ref;
                    //         event.id = doc.id;
                    //         this.events.push(event);
                    //       });
                    //     });
                    // }
                    this.departments.push(prog);
                  });
                });
            });
          });
      }
    },
  },
  computed: {
    ...mapState(["userProfile", "currentUser"]),
    ...sync("*"),
    zzschool() {
      return this.userData.ppschool;
    },
  },

  created() {
    this.currentWeekNumber = this._getWeeksInMonth(2022, 11) 
    this.selectedMonth = new Date().getMonth() + 1;
    if (this.zzschool != null && this.zzschool != "") this._setupp();
  },
};
</script>

<style>
.bborder td {
  border-right: 1px solid #bbb !important;
}
.bborder th {
  border-right: 1px solid #bbb;
  border-top: 1px solid #bbb;
}
.bborder .borderCell:hover {
  background-color: red;
}
.v-chip .v-chip__content {
  align-items: start !important;
  display: inline-flex;
  height: 100%;
  max-width: 100%;
}
</style>